<template>
  <div class="radioRange">
    <input
      :key="i + 1"
      :name="name"
      :readonly="readonly"
      :value="i + 1"
      type="radio"
      class="radioRange__input"
      :class="{ '-checked': i + 1 <= value, '-active': i + 1 == value }"
      @click="checked(i + 1)"
      v-for="i in Array(max).keys()"
      >
  </div>
</template>
<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number
    },
    name: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      default: 7
    },
  },
  methods: {
    checked(n) {
      if (!this.readonly) {
        if (this.value === n) {
          this.$emit('input', 0)
        } else {
          this.$emit('input', n)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.radioRange {
  border-radius: 4px;;
  border: 1px solid;
  display: flex;
  margin: 0;
  padding: 3px;
  vertical-align: middle;

  &__input {
    appearance: none;
    border: 1px solid;
    color: inherit;
    display: inline-block;
    flex-grow: 1;
    height: .25rem;
    margin: 0;
    width: 1rem;

    & + & {
      margin-left: 2px;
    }

    &.-checked {
      background-color: currentColor;
    }

    &:focus {
      outline: none;
      border-color: var(--ghost);
    }
  }
}
</style>
