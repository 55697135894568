<template>
  <div ref="Chat" class="Chat">
    <div class="Chat__messages">
      <div class="Chat__message Message" v-for="msg in history" :key="msg.id">
        <div class="Message__time" v-text="(new Date(msg.timestamp)).toLocaleTimeString()"></div>
        <div class="Message__player" v-text="msg.data.playerId"></div>
        <div class="Message__content" v-text="msg.data.message"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['history'])
  },
  watch: {
    history() {
      this.$nextTick(() => {
        const Chat = this.$refs.Chat;
        if (Chat.scrollHeight > Chat.clientHeight) {
          Chat.scrollTop = Chat.scrollHeight;
        }
      });
    }
  }
}
</script>
<style lang="scss">
.Chat {
  height: 100%;
  overflow-y: scroll;
  color: var(--moon);
  background: var(--night);
  border: 6px double var(--moon);
  box-shadow: 0 0 0 2px var(--stone), 0 0 0 2px inset var(--stone), 0 5px 20px 0 inset var(--stone);
  margin: 6px;


  &__message {
    display: flex;
  }
}

.Message {
  font-family: 'VT323';
  margin-bottom: 1em;
  font-size: 1.25rem;

  &__time {
    color: var(--grave);
    width: 6rem;
    text-align: center;
  }

  &__player {
    color: var(--ghost);
    margin-right: .25rem;
  }
}
</style>
