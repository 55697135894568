<template>
  <div class="rating" :title="label">
    <svg class="rating__label icon"><use :xlink:href="`#${label.toLowerCase()}`"></use></svg>
    <input
      class="rating__input"
      type="text"
      :disabled="!!readonly"
      :value="signedValue"
      @input="$emit('input', $event.target.value)"
      @keydown.up="up"
      @keydown.down="down"
    >
  </div>
</template>
<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      required: true
    },
    label: {
      type: String,
    }
  },
  computed: {
    signedValue() {
      return this.value > 0 ? `+${this.value}` : this.value;
    }
  },
  methods: {
    up() {
      if (!this.readonly) {
        this.$emit('input', this.value + 1);
      }
    },
    down() {
      if (!this.readonly) {
        this.$emit('input', this.value - 1);
      }
    }
  },
}
</script>
<style lang="scss">
.rating {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &__input {
    background-color: transparent;
    border: none;
    color: inherit;
    font-family: 'VT323';
    font-weight: 400;
    font-size: 1.3125rem;
    text-align: left;
    width: 2em;
    line-height: 1;
  }

  &__label {
  }
}
.icon {
  width: 1rem;
  height: 1rem;
}
</style>
