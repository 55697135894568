<template>
  <div class="Dicebox"></div>
</template>
<script>
import { mapState } from 'vuex'
const gross = require('../gross/dice.js')

export default {
  box: null,
  data: () => ({
    diceColor: '#a6f2a9',
    labelColor: '#12171a',
  }),
  computed: {
    ...mapState(['dice', 'box'])
  },
  mounted() {
    this.$store.commit('setBox', gross.dice_initialize(this.$el))
  },
  watch: {
    dice() {
      this.box.rethrow(this.dice, this.diceColor, this.labelColor)
    }
  },
  methods: {
    roll() {
      let isNewRoll = true
      this.box.throw(result => {
        const signature = result.reduce((a, b) => a * 23 + b, 0)
        if (isNewRoll) {
          this.$emit('rolled', { result, signature, playerId: this.playerId })
          isNewRoll = false
        }
      }, this.diceColor, this.labelColor)
    }
  }
}
</script>
