<template>
  <div class="Controls">
    <div class="Controls__signin">
      <button v-if="playerId" class="Button -link" @click="signIn">
        You are signed in as {{ playerId }}. Change?
      </button>
      <button v-else class="Button -link" @click="signIn">Sign In</button>
    </div>
    <div class="Controls__actions">
      <button class="Button -roll" @click="$store.dispatch('roll')">Roll</button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['playerId'])
  },
  methods: {
    signIn() {
      const id = prompt("Specify your name:", this.playerId || 'Player')
      if (id) {
        this.$store.commit('setPlayer', id)
      }
    }
  }
}
</script>
<style lang="scss">
.Controls {
  display: grid;
  grid-template-columns: 1fr 25rem;
  grid-template-areas: "actions signin";
  align-items: center;

  &__actions {
    grid-area: actions;
    justify-self: center;
  }

  &__signin {
    grid-area: signin;
    justify-self: end;
  }
}

.Button {
  background-color: transparent;
  cursor: pointer;
  padding: 1rem 1.618rem;
  color: var(--moon);
  border: 6px double var(--moon);
  box-shadow: 0 0 0 2px var(--stone), 0 0 0 2px inset var(--stone), 0 5px 20px 0 inset var(--stone);
  background: linear-gradient(0, var(--night), var(--stone));
  margin: 6px;

  &:hover {
    border-color: var(--glow);
    color: var(--glow);
  }

  &:focus {
    outline: 0;
    position: relative;

    &::after {
      border-radius: 2rem;
      box-shadow: 0 0 0 2px var(--ghost);
      content: '';
      inset: -.25rem;
      position: absolute;
    }
  }

  &.-roll {
    font-family: 'VT323';
    font-size: 1.5rem;
  }
}
</style>
