<template>
  <div class="Character" :class="{'-inactive': character.inactive}">
    <div class="Character__title">
      <div class="Character__name" v-text="character.name" :title="character.type"></div>
      <!--<div class="Character__type" v-text="character.type"></div>-->
    </div>
    <!--<img class="Character__image" src="https://picsum.photos/320" alt="">-->
    <div class="Character__statuses">
      <div class="Status -harm" :class="{'-unstable': character.unstable }" :title="`${character.harm} Harm${character.unstable ? ', unstable' : ''}`">
        <radio-range class="Status__value" name="harm" :readonly="!canEdit" v-model="character.harm" />
      </div>
      <div class="Status -luck" :title="`${character.luck} Luck`">
        <radio-range class="Status__value" name="luck" :readonly="!canEdit" v-model="character.luck" />
      </div>
      <div class="Status -experience" :title="`${character.experience} Experience`">
        <radio-range class="Status__value" name="experience" :readonly="!canEdit" v-model="character.experience" :max="5" />
      </div>
    </div>
    <div class="Character__ratings">
      <div class="Character__rating">
        <rating label="Charm" :readonly="!canEdit" v-model.number="character.charm" />
      </div>
      <div class="Character__rating">
        <rating label="Cool" :readonly="!canEdit" v-model.number="character.cool" />
      </div>
      <div class="Character__rating">
        <rating label="Sharp" :readonly="!canEdit" v-model.number="character.sharp" />
      </div>
      <div class="Character__rating">
        <rating label="Tough" :readonly="!canEdit" v-model.number="character.tough" />
      </div>
      <div class="Character__rating">
        <rating label="Weird" :readonly="!canEdit" v-model.number="character.weird" />
      </div>
    </div>
  </div>
</template>
<script>
import RadioRange from './RadioRange';
import Rating from './Rating';

export default {
  components: {
    RadioRange,
    Rating
  },
  props: {
    character: {
      type: Object,
      required: true
    }
  },
  computed: {
    canEdit() {
      return this.$store.getters.canEditCharacter(this.character)
    }
  },
  watch: {
    character: {
      deep: true,
      handler() {
        if (this.canEdit) {
          this.$store.dispatch('updateCharacters')
        }
      },
    },
  }
}
</script>
<style lang="scss">
.Character {
  display: grid;
  grid-template-areas: "title statuses" "ratings statuses";
  padding: 1rem;
  background: var(--night);
  border: 6px double var(--moon);
  box-shadow: 0 0 0 2px var(--stone), 0 0 0 2px inset var(--stone), 0 5px 20px 0 inset var(--stone);
  margin: 6px;

  &__title {
    grid-area: title;
    display: flex;
    justify-content: space-between;
  }

  &__name {
    font-family: 'VT323';
    font-size: 1.272rem;
  }

  &__type {
    color: var(--moon);
    align-self: center;
    justify-self: end;
    font-family: 'VT323';
  }

  &__statuses {
    grid-area: statuses;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__ratings {
    display: flex;
    grid-area: ratings;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__rating {
    flex-basis: 33.333%;
    margin-top: .25rem;
  }

  &.-inactive {
    filter: saturate(0) brightness(.6);
  }
}

@keyframes flash {
  0%, 100% { color: var(--fresh); }
  50% { color: var(--undeath); }
}

.Status {
  $b: &;

  height: 1rem;

  &.-experience {
    #{$b}__value {
      color: var(--glow);
    }
  }
  &.-luck {
    #{$b}__value {
      color: var(--moss);
    }
  }
  &.-harm {
    #{$b}__value {
      color: var(--fresh);
    }

    &.-unstable {
      #{$b}__value {
        animation: flash ease-in-out 3s infinite;
      }
    }
  }
}

.Rating {
}
</style>
