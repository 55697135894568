import Vue from 'vue'
import Vuex from 'vuex'
const Ably = require('ably')

Vue.use(Vuex)

let playerId = localStorage.playerId || Math.random().toString(36)
localStorage.playerId = playerId;
localStorage.version = 1

const characters = [
  {
    "name":"Kyle Dundas",
    "type":"the Mundane",
    "charm":2,
    "cool":1,
    "experience":1,
    "harm":1,
    "unstable": true,
    "luck":6,
    "sharp":0,
    "tough":1,
    "weird":-1
  }, {
    "name":"Bethany Krinkle",
    "type":"the Krooked",
    "charm":-1,
    "cool":1,
    "experience":5,
    "harm":3,
    "luck":7,
    "sharp":1,
    "tough":2,
    "weird":0
  }, {
    "name":"Clyde Wayne",
    "type":"the Professional",
    "charm":0,
    "cool":1,
    "experience":3,
    "harm":0,
    "luck":7,
    "sharp":-1,
    "tough":2,
    "weird":-1,
  }, {
    "name":"Tom",
    "type":"the Monstrous",
    "charm":-1,
    "cool":1,
    "experience":0,
    "harm":0,
    "luck":7,
    "sharp":1,
    "tough":0,
    "weird":3,
  }, {
    "name":"Helen",
    "type":"the Chosen",
    "charm":-1,
    "cool":2,
    "experience":0,
    "harm":0,
    "luck":7,
    "sharp":1,
    "tough":2,
    "weird":-1,
  }, {
    "name":"Betsy Johnson",
    "type":"the Spooky",
    "charm":1,
    "cool":0,
    "experience":1,
    "harm":0,
    "luck":7,
    "sharp":1,
    "tough":-1,
    "weird":3,
    "inactive": true,
  }
]

const store = new Vuex.Store({
  state: {
    box: null,
    playerId,
    characters,
    channel: null,
    dice: [3, 4],
    history: [],
  },

  getters: {
    canEditCharacter(state) {
      return () => localStorage.isKeeper;
    },
  },

  mutations: {
    setBox(state, box) {
      state.box = box
    },
    addMessage(state, message) {
      state.history.push(message)
    },
    setDice(state, result) {
      state.dice = result
    },
    setPlayer(state, id) {
      state.playerId = id
      localStorage.playerId = id
    },
    setCharacters(state, characters) {
      if (JSON.stringify(characters) != JSON.stringify(state.characters)) {
        state.characters = characters
      }
    }
  },

  actions: {
    reroll({ state, commit }, result) {
      state.box.rethrow(result)
      commit('setDice', result)
    },
    roll({ state, commit }) {
      state.box.throw(result => {
        commit('setDice', result)
        gameChannel.publish('messages', {
          result,
          message: `rolled ${result.join(' + ')} = ${result.reduce((a,b) => a + b, 0)}.`,
          playerId: state.playerId,
        })
      })
    },
    updateCharacters({ state, commit }) {
      playerChannel.publish('characters', state.characters);
    }
  }
})


const ably = new Ably.Realtime('6wXncQ._ktezQ:CZnc8pyINct2b_QA')
const gameChannel = ably.channels.get('[?rewind=100]gameChannel')
gameChannel.subscribe('messages', payload => {
  store.commit('addMessage', payload)
  if (payload.data.playerId && playerId !== payload.data.playerId) {
    store.dispatch('reroll', payload.data.result)
  }
})

const playerChannel = ably.channels.get('[?rewind=1]playerChannel')
playerChannel.subscribe('characters', payload => {
  if (payload.data && payload.data.length) {
    store.commit('setCharacters', payload.data);
  }
})



export default store
