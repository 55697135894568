import Vue from 'vue'
import { mapState } from 'vuex'

import AppCharacter from './components/AppCharacter.vue'
import AppChat from './components/AppChat.vue'
import AppControls from './components/AppControls.vue'
import AppDicebox from './components/AppDicebox.vue'
import store from './store'

const app = new Vue({
  store,
  components: {
    AppCharacter,
    AppChat,
    AppControls,
    AppDicebox
  },
  computed: {
    ...mapState(['characters', 'dice'])
  }
})

app.$mount('#root')

